import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private username: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private id: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private priviledge: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private token: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor() { }

  setData(data: any) {
    this.username.next(data['username']);
    this.id.next(data['id']);
    this.priviledge.next(data['priviledge']);
    this.token.next(data['token']);
  }

  getData() {
    return {
      id: this.id.value,
      username: this.username.value,
      priviledge: this.priviledge      
    }
  }

  setSession(data:any){
    this.setData(data);
    localStorage.setItem('currentUser', JSON.stringify(data));
  }

  destroySession(){
    this.username.next(null);
    this.id.next(null);
    this.priviledge.next(null);
    this.token.next(null);
    localStorage.clear();
  }

  getSessionData(){    
    let data = JSON.parse(localStorage.getItem('currentUser'));
    if (data != null)
      this.setData(data);
  }

  getToken() {
    return this.token.value;
  }

  setToken(token: string) {
    this.token.next(token);
  }

  getID() {
    return this.id.value;
  }

  setID(id: number) {
    this.id.next(id);
  }

  getUsername() {
    return this.username.value;
  }

  checkAccess(type, access) {
    let privs = this.priviledge.value;
    for (let priv of privs) {
      if (priv.type == type && priv.access == access) return priv.access_level;
    }
    return null;
  }

  getAccessActions(type, access) {
    let privs = this.priviledge.value;
    for(let priv of privs) {
      if (priv.type == type && priv.access == access) return priv.actions;
    }
    return null;
  }
}
