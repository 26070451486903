import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { SessionService } from './session.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private session: SessionService) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {   
    return new Promise((resolve, reject) => { 
      this.session.getSessionData();     
      let token = this.session.getToken();
      if (token == null) {
        resolve(false);
        this.router.navigate(["/login"]);        
      } else {
        resolve(true);
      }                  
    });
  }
  
}
